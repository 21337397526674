<template>
  <v-container fluid>
    <v-row>
      <v-col md="6" cols="12">
        <div class="conOfHeader">
          <div class="headerOfPage">Project Details</div>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="listData && listData.length > 0">
      <v-col cols="12">
        <section class="conOfProjects">
          <div class="conOfTable">
            <v-data-table
              :headers="headers"
              :items="listData"
              hide-default-footer
            >
            </v-data-table>
          </div>
        </section>
      </v-col>
    </v-row>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <v-row v-else>
      <v-col cols="12" class="mt-7">
        <EmptyState />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
import EmptyState from "@/modules/shared/components/emptystate";
export default {
  data: () => ({
    isLoading: false,
    listData: [],
    headers: [
      { text: "Project Name", value: "project_name" },
      { text: "Client Name", value: "client_name" },
      { text: "Sales", value: "sales_in_charge" },
      { text: "Assign to", value: "assign_to" },
      { text: "Project Country", value: "project_country" },
      { text: "Timeline", value: "project_timeline" },
      { text: "importance", value: "importance" },
    ],
  }),
  computed: {},
  components: { EmptyState },
  created() {
    this.getListData();
  },
  watch: {},
  methods: {
    getListData() {
      this.isLoading = true;
      apiServices
        .get(`project-categories?project_id=${this.$route.params.id}`)
        .then((res) => {
          if (res.data) {
            this.isLoadingSearch = false;
            this.listData = res.data;
            console.log("listData", this.listData);

            this.isLoading = false;
          } else {
            this.isLoading = false;
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
